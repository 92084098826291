import {
  FETCH_APPLICATION_LOADING,
  FETCH_APPLICATION,
  FETCH_HEADER_LOADING,
  FETCH_HEADER,
  FETCH_TOOL,
  FETCH_TOOL_LOADING,
  FETCH_NINE_DOT_HEADER,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  applicationLoading: false,
  applications: null,
  headerLoading: false,
  toolLoading: false,
  headerMenu: null,
  toolMenu: null,
  ninedotmenu: null,
};

const authReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_APPLICATION_LOADING:
      return {
        ...state,
        applicationLoading: payload,
      };
    case FETCH_APPLICATION:
      return {
        ...state,
        applications: payload,
      };
    case FETCH_HEADER_LOADING:
      return {
        ...state,
        headerLoading: payload,
      };
    case FETCH_TOOL_LOADING:
      return {
        ...state,
        toolLoading: payload,
      };
    case FETCH_HEADER:
      return {
        ...state,
        headerMenu: payload,
      };
      case FETCH_NINE_DOT_HEADER:
        return {
          ...state,
          ninedotmenu: payload,
        };
    case FETCH_TOOL:
      return {
        ...state,
        toolMenu: payload,
      };
    default:
      return state;
  }
});

export default authReducer;
